import { inject } from '@angular/core';
import { AppService } from './app.service';
import { HookType } from '@btlib-core';
import { MiscUtil } from '@btlib-core';


export class BergensportsreiserService {

  constructor(public apps: AppService ) {
    if (this.apps.bas.envtest) console.log("BergensportsreiserService.constructor");


    this.apps.bas.hs.on(
      HookType.WebApiRequest, 
      (params) => this.onWebApiRequest(params),
      50
    );


    if (this.apps.bas.envtest) {
      this.apps.bas.ts.init({
  
        // pixel: {
        //   enabled: true,
        //   id: "1392750311341973", // app.test.cloudbooking.io
          
        // },
        gtm: {
          enabled: true,
          id: "GTM-NQL4QB4F",  // app.test.cloudbooking.io
        }
      });
    } else {

      this.apps.bas.ts.init({
  
        pixel: {
          enabled: true,
          id: "661472214813161", // bergensportsreiser.no
          
        },
        gtm: {
          enabled: true,
          id: "GTM-WJGW62C", 
        }
      });
      
    }
    

   
  }


  onWebApiRequest(params:any) {
    
    return new Promise<boolean>((res, rej) => {
      if (this.apps.bas.envtest) console.log("BergensportsreiserService.onWebApiRequest: ", params);

     
      let qp = params.queryParams || { };

      let page = decodeURIComponent( qp["cbapi-page"] );
      let action = qp["cbapi-action"];
      let toolsAction = qp["cbapi-tools-action"];


      let target = "";
      let queryParams:any = { };

      for (let pn in qp) {
        if (!pn.startsWith("cbapi-option-")) continue;
        let name = pn.substring("cbapi-option-".length);
        queryParams[name] = qp[pn];
      }


      


      if (page) {

        target = "/" + page;

        
        if (page == "index") {
          target = "/";
        } else if (page == "order/payment") {

          // target = "/lib" + target; // /lib/order/payment/?id=dfdf
          target = "/common/order/payment";

        }


        
      }

      if (action) {
        if (action == "showNewPasswordDialog") {
          // TODO:test
          target = "/lib/user/password";


          // this.apps.bas.ws.login({
          //   checkLogin: true,
          //   uid: qp["cbapi-uid"] || "",
          //   accessKey: qp["cbapi-ak"] || "",
          //   callback: (al:any) => {
          //     console.log("al: ", al);
          //     if (!al.success) {
          //       target = "/";
          //     }
          //     this.apps.bas.ui.router.navigate( [ target ] );

          //   }
          // }).toPromise().then(() => { });

          return;
        }
        if (action == "mypage") {
          // target = "/bergensportsreiser/";

          // if (this.apps.bas.envtest) {
          //   this.apps.bas.es.sendParentMessageRedir("https://site20.test2.web.booktech.no/bergen-sportsreiser-min-side/");
          //   return;
          // }
          this.apps.bas.es.sendParentMessageRedir("https://bergensportsreiser.no/minside/");

          
          // this.apps.bas.ui.router.navigate(
          //   [ target ], 
          //   { queryParams: queryParams }
          // );
        }
      }


      if (toolsAction == "confirmUserLp")  {

        target = "/bergensportsreiser/user/index";
        this.apps.bas.ui.success(this.apps.bas.ui.actrans("Brukeren din er nå aktivert og du kan logge inn med passordet du valgte."))
      }


      if (this.apps.bas.envtest || !target) console.log("bss.target: " + target + ", queryParams: ", queryParams);
      
      if (target) {
          this.apps.bas.ui.router.navigate(
            [ target ], 
            { queryParams: queryParams }
          );
          res(false);
      }

      res(false);
      

      // res(true);
    });
  }



  findDayRate(roomDayRateConfig:any, arrival:Date|String, hotelPid:string|undefined, dayRate:number, cap:number) {
    let level = this.findDayRateConfig(roomDayRateConfig, arrival, hotelPid);

  
    if (level.change === 0) return dayRate;

    let calc =  dayRate * (1 + (level.change / 100.0));
    // if (this.apps.bas.envtest) console.log("findDayRate, amount: " + amount + " -> " + calc + "; level; ", level);

    // Avrund til nærmeste 100

    calc = Math.floor(calc / 100); // runder ned

    let rest = calc % cap;
    if (rest != 0) {
      calc += (cap - rest);
    }

    let rounded = calc * 100;

    if (this.apps.bas.envtest) console.log("findDayRate, amount: " + dayRate + ", c: " + level.change + " -> " + calc + ", r: " + rounded + "; level; ", level);

    return rounded;
  }

  findDayRateConfig(roomDayRateConfig:any, arrival:Date|String, hotelPid:string|undefined) {
    let empty = { level: -1,  "daysToArrivalMin": -1, "daysToArrivalMax": -1, change: 0 };

    if (!roomDayRateConfig) return empty;

    if (typeof arrival == "string") {
      let arrDate = MiscUtil.setDateAsString(arrival);
      if (arrDate == null) {
        console.log("arrDate == null, arrival: " + arrival);
        return empty;
      }
      arrival = arrDate;
    }

    let days = this.apps.bas.ui.differenceInDays(arrival as Date, new Date());





    let levels = roomDayRateConfig.levels || [];
    let overrides = roomDayRateConfig.overrides || { };


    if (hotelPid && overrides[hotelPid]) levels = overrides[hotelPid].levels || [];

    
    // if (this.apps.bas.envtest) console.log("findDayRateConfig"
    //   +", arrival: " + arrival 
    //   + ", days; " + days
    //   + ", hotelPid: " + hotelPid 
    //   + ", levels: ", levels
    //   // + ", rdrc.str: " + JSON.stringify(roomDayRateConfig)
    //   // + ", rdrc: ", roomDayRateConfig
    // );

    let level = empty;
    for (let l of levels) {
      if (l.daysToArrivalMin <= days && days <= l.daysToArrivalMax ) {
        level = l;
        break;
      }
    }

    if (level.change === undefined) {
      console.log("level.change === undefined, level: ", level);
      level = empty; 
    }

    return level;
  }



}
