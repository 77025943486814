import { Injectable, inject } from '@angular/core';

import { BooktechAppService } from '@btlib-core';

import { BergensportsreiserService } from './bergensportsreiser.service';

export const APP_DATAID = {

}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  name = "AppService"

  

  public bas = inject(BooktechAppService);
  bss:BergensportsreiserService|undefined;

  constructor() {
    if(this.bas.envtest) console.log("AppService: ", this.bas.settings?.appId);

    let cid = this.bas.ds.findCid();
    if (cid == "bergensportsreiser") this.bss = new BergensportsreiserService(this);

  }

}
